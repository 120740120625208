import loginLogo1080 from '@/assets/img/company/zhonglv/logo.png';
import menuLogo1080 from '@/assets/img/company/zhonglv/menu_logo.png';

export let config = {
  platId: '1005', //分销商平台
  //platId: '1003',//思客商旅平台id
  distributionWebsite: [
    'uatparter.ceekee.com',
    'uatebooking.ceekee.com',
    '192.168.120.223',
    'partner.ceekee.com',
    '192.168.0.45',
  ],
  noShowEnterpriseId: [746728], // 不展示资源预定、结算管理菜单，消费场景收费标准内容企业
};

export let agentUrlList = ['booking.tmctrip.com', 'uatbooking.tmctrip.com'];

export const companyConfig = new Map([
  [
    '1080',
    {
      platId: '1080',
      loginLogoUrl: loginLogo1080,
      menuLogoUrl: menuLogo1080,
      appName: '广东中旅',
      abbr: '中旅',
    },
  ],
  [
    '1083',
    {
      platId: '1083',
      loginLogoUrl: 'https://image.ceekee.com/tmc/dossen/logo_pclogin.png',
      menuLogoUrl: 'https://image.ceekee.com/tmc/dossen/logo_pchome.png',
      appName: '呈企通',
      abbr: '东呈',
    },
  ],

  [
    'default',
    {
      platId: 'default',
      loginLogoUrl: 'https://image.ceekee.com/pc2.0/old/logo.png',
      menuLogoUrl: 'https://image.ceekee.com/pc2.0/logoV2.png',
      appName: '差旅管家',
      abbr: '思客',
    },
  ],
]);
