import request from '../request/request.js';
import { ElMessage } from 'element-plus';
import tool from '../common/tool.js';
import { useEnterpriseStore } from '@/store/modules/enterprise.js';

export function updateEnterpriseInfo(enterpriseId) {
  return new Promise((resolve) => {
    let origin = window.location.origin;
    if (origin.includes('http://localhost')) {
      origin = 'https://uatmanage.ceekee.com';
    }
    const param = {
      agentPlatId: '',
      clientType: 'h5',
      hostUrl: origin,
      enterpriseId: enterpriseId || 0,
    };
    request({
      apiModule: {
        module: 'tripwise-m-api',
        address: 'agent-account/get-plat-info',
        method: 'post',
      },
      data: param,
    }).then((res) => {
      if (res.data) {
        const enterpriseInfo = res.data;
        const enterpriseStore = useEnterpriseStore();
        enterpriseStore.updateEnterpriseConfigInfo(enterpriseInfo);
        if (res.data.platId) {
          tool.setLocalStorage('PlatId', res.data.platId);
        }
        resolve(enterpriseInfo);
      } else {
        ElMessage.error(res.message);
      }
    });
  });
}
